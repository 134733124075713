<template>
  <div class="header-main-logo">
    <img src="/logo-color.svg" alt="" />
  </div>
</template>

<style scoped>
img {
  width: 120px;
  max-height: 65px;
}
</style>
